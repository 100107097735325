import React from 'react';
import * as classes from './UpcomingEvent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDuration, momentDate } from '../../../scripts/utils';
import moment from 'moment';
import RenderFolderNames from '../../Details/DetailsFolderNames';
import { useTranslation } from 'react-i18next';
import { BuyTickets } from './UpcomingEvent';
import Header from '../../../shared/Header/Header';

export default function EventsRoomDescription({
  ticketAccessGranted,
  singleEvent,
  ticketAccess,
  liveEvent,
  defaultLanguage,
  setTicketAccessGranted,
  routes,
  futureEvents,
  styles,
}) {
  const { t } = useTranslation();

  // if access is granted we show room info else event info

  const singleItem = ticketAccessGranted ? singleEvent : singleEvent;
  return (
    <div className={`${classes.EventTertairy} smallcasebold`}>
      <div className={classes.EventPrimary}>
        <Header
          extraClassname={true}
          title={
            singleEvent?.publicName[defaultLanguage] ||
            singleEvent?.name[defaultLanguage]
          }
          styles={styles}
        />
        {ticketAccess && !ticketAccessGranted && (
          <>
            {liveEvent && (
              <div className={`${classes.EventPrimary_countdown} font-600`}>
                {t('eventsCategory.eventStarted')}
              </div>
            )}
          </>
        )}

        <div className={classes.EventTimeDescription}>
          <div className={`${classes.EventPrimary_date} font-300`}>
            <div>
              <FontAwesomeIcon icon='calendar' />
            </div>
            <div>{momentDate(singleItem?.startTime)}</div>
          </div>
          <div className={`${classes.EventPrimary_time} font-300`}>
            <div>
              {' '}
              <FontAwesomeIcon icon='clock' />
            </div>
            <div>{moment(singleItem?.startTime).locale('fi').format('LT')}</div>{' '}
          </div>
          <div className={`${classes.EventPrimary_time} font-300`}>
            <div>
              {' '}
              <FontAwesomeIcon icon='hourglass-start' />
            </div>
            <div>{convertDuration(singleItem?.durationPublic)}</div>{' '}
          </div>
        </div>

        <RenderFolderNames item={singleEvent.categories} />

        {ticketAccess && !ticketAccessGranted && (
          <BuyTickets
            routes={routes}
            futureEvents={futureEvents}
            singleEvent={singleEvent}
            setTicketAccessGranted={setTicketAccessGranted}
          />
        )}

        <div
          className={classes.EventPrimary_description}
          dangerouslySetInnerHTML={{
            __html: singleItem?.description[defaultLanguage],
          }}
        ></div>
      </div>
    </div>
  );
}
