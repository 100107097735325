import { useMyContext } from '../../../../../contexts/StateHolder';
import RenderItem from '../../../RenderItems/RenderItem';
import CategoryTitle from '../../../CategoryTitles/CategoryTitle';
import CategoryTitle2 from '../../../CategoryTitles/CategoryTitle2/CategoryTitle2';
import { useEffect, useState } from 'react';
import { getSeries, getEpisodes } from '../../../../../scripts/dataHandlers';
import { createToken, createGroupItemId } from '../../../../../scripts/utils';
import Carousel from 'react-multi-carousel';
import * as classes from './EpisodesWithSerieTitles.module.css';
import { FiCloudLightning } from 'react-icons/fi';

// List of usable category components
const components = {
  CategoryTitle,
  CategoryTitle2,
};
/*
Fetch: getAssets with props.groupItemId
Renders: props categories with title and content in slick slider
*/
const EpisodesWithSerieTitles = (props) => {
  // Destructure props.settings
  const {
    id,
    routes,
    slickSettings,
    assetProperty,
    categoryTitleComponent,
    itemImageComponent,
    itemTitleComponent,
    maintainImageAspectRatio,
  } = props.settings;

  // Destructure props.styles

  const [allSerieItems, setAllSerieItems] = useState([]);

  // Bring stateholders from context
  const { allCategories, organizationId, key, language, user } = useMyContext(); // + allCategoryItems, setAllCategoryItems when using context

  // UseEffect that will check mode and context data by id, if component has already fetched items once
  console.log(allCategories, 'allCategories');
  useEffect(() => {
    const getDataFromAPI = async () => {
      try {
        const res = await getSeries(
          organizationId,
          key,
          language,
          user,
          assetProperty
        );
        console.log(res, 'res1');

        console.log('EpisodesWithSerieTitles, getSeries: ', res);

        let objectData = [];

        // await
        res.map(async (season) => {
          try {
            const groupIdString = createGroupItemId(season.groupItems);

            // Call createToken function to create new token from given data
            let serieToken = createToken(organizationId, groupIdString, key);

            const response = await getEpisodes(
              organizationId,
              groupIdString,
              serieToken,
              language,
              props.assetProperty,
              user
            );

            // and response need to be added into final response array
            objectData.push({ category: season, assets: response });
            setAllSerieItems([...objectData]);
          } catch (error) {
            console.log('error' + error);
          }
        });

        // setAllSerieItems(objectData);
      } catch (err) {
        console.log(err);
      }
    };

    if (organizationId && key && language && slickSettings) {
      getDataFromAPI();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories, language]);

  console.log(allSerieItems, 'allSerieItems.');
  const renderSeasons = () => {
    console.log(1);
    return allSerieItems.map((serie) => {
      // Choose child component for Title
      const TitleComponent = components[categoryTitleComponent];

      // Sort by seasons and episodenumbers
      serie?.assets?.sort((a, b) => {
        if (a.seasonNumber < b.seasonNumber) {
          return -1;
        } else if (a.seasonNumber > b.seasonNumber) {
          return 1;
        } else {
          return a.episodeNumber - b.episodeNumber;
        }
      });

      return serie?.assets?.length > 0 ? (
        <div
          className={classes.categoriesContainer}
          key={`${id} ${serie.category.id}`}
        >
          <TitleComponent
            id={serie.category.id}
            title={serie.category.title}
            routes={routes}
            // styles={styles}
            item={serie.category}
          />

          <Carousel {...slickSettings}>
            {serie.assets.map((item, i) => {
              item.serieId = serie.category.id;
              return (
                <RenderItem
                  key={`${id} ${i}`}
                  pushRoute={`/${routes.serieRoute}/${organizationId}/${item.id}`}
                  item={item}
                  routes={routes}
                  playOnClick={true}
                  itemImageComponent={itemImageComponent}
                  itemTitleComponent={itemTitleComponent}
                  showCategoryName={true}
                  showPlayIcon={true}
                  // whether to maintain aspect ratio 16/9
                  maintainImageAspectRatio={maintainImageAspectRatio}
                  isSerie={true}
                />
              );
            })}
          </Carousel>
        </div>
      ) : null;
    });
  };
  return allSerieItems.length > 0 ? renderSeasons() : null;
};

export default EpisodesWithSerieTitles;
